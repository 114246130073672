import React from "react"
import styled from "styled-components"
import Link from "../../components/Link"
import Page from "../../components/Page"
import Box from "../../components/Box"
import Paragraph from "../../components/Paragraph"

const ChildPages = styled.ul``

const ChildPage = styled.li`
  list-style: circle;
  margin-bottom: 2rem;

  a {
    font-size: 1.5em;
    text-decoration: none;
    line-height: 1.5;
  }

  p {
    font-size: 1.2em;
  }
`

const Legal = () => (
  <Page title={"Legal"}>
    <Box as={"nav"} role={"navigation"}>
      <ChildPages>
        <ChildPage>
          <Link to={"/legal/terms-of-service"} title={"Terms of service"}>
            Terms of service
          </Link>
          <Paragraph>
            These terms of service describe your rights and responsibilities
            when using our software and related services.
          </Paragraph>
        </ChildPage>
        <ChildPage>
          <Link to={"/legal/privacy-policy"} title={"Privacy policy"}>
            Privacy policy
          </Link>
          <Paragraph>
            Personal information we collect about you and how we use it.
          </Paragraph>
        </ChildPage>
        <ChildPage>
          <Link to={"/legal/cookie-policy"} title={"Cookie policy"}>
            Cookie policy
          </Link>
          <Paragraph>
            We use cookies on our website to distinguish you from other users of
            our website. This help us provide you with a good user experience
            when you browse our website and allows us to improve our product.
          </Paragraph>
        </ChildPage>
      </ChildPages>
    </Box>
  </Page>
)

export default Legal
